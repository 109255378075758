import React, { useContext, useEffect, useState } from "react";
import dateFormat from 'dateformat';
import { Container, Row, Table, Form } from "react-bootstrap";
import { navigate } from "gatsby";

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import 'gatsby-theme-starberry-lomondgroup/src/scss/_sitemap.scss';
import { getFormData } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import PaymentTable from "gatsby-theme-starberry-lomondgroup/src/components/Payment/PaymentTable"

import {PageLinks} from "../common/page-links";
import { shasign_pass } from "../common/constant";

// markup
const PaymentVerify = (props) => {

    const[payment_info, setPaymentInfo] = useState("");

    var sha512 = require('js-sha512');

    var payment_id = typeof window !== 'undefined' && (localStorage.getItem("payment_id") ? localStorage.getItem("payment_id") : "");
    // var payment_json = typeof window !== 'undefined' && (localStorage.getItem("payment_info") ? localStorage.getItem("payment_info") : "");

    //console.log("payment_info", payment_json, payment_json && JSON.parse(payment_json))
    //var payment_info = typeof window !== 'undefined' && (payment_json ? JSON.parse(payment_json) : navigate(`/${PageLinks.online_payment}/`));

    useEffect(() => {
        if(payment_id){
            getFormData(payment_id).then(async apiRes => {

                //console.log("apiRes ==>", apiRes);

                if (apiRes?.success === true) {
                    setPaymentInfo(apiRes)
                }
            });
        } else{
            navigate(`/${PageLinks.online_payment}/`)
        }
    }, [payment_id]);

    const goBack = () =>{
        typeof window !== 'undefined' && window.history.go(-1); return false;
    }

    var seo_meta = {title: "Payment Verification", description:"Payment Verification"}

    var cur_date = new Date();
    var order_id = payment_info?.extra?.ORDERID
    //var order_id = `37314`

    var secret_code = shasign_pass;
    var amount = payment_info?.extra?.AMOUNT;

    // var shasign_str = `AMOUNT=${amount}${secret_code}CURRENCY=${payment_info?.extra?.CURRENCY}${secret_code}LANGUAGE=${payment_info?.extra?.LANGUAGE}${secret_code}ORDERID=${order_id}${secret_code}PSPID=${payment_info?.extra?.PSPID}${secret_code}`

    var shasign_str = `ACCEPTURL=${payment_info?.extra?.ACCEPTURL}${secret_code}AMOUNT=${amount}${secret_code}CANCELURL=${payment_info?.extra?.CANCELURL}${secret_code}CN=${payment_info?.extra?.CN}${secret_code}COMPLUS=${payment_info?.extra?.COMPLUS}${secret_code}CURRENCY=${payment_info?.extra?.CURRENCY}${secret_code}DECLINEURL=${payment_info?.extra?.DECLINEURL}${secret_code}EMAIL=${payment_info?.email}${secret_code}EXCEPTIONURL=${payment_info?.extra?.EXCEPTIONURL}${secret_code}HOMEURL=${payment_info?.extra?.HOMEURL}${secret_code}LANGUAGE=${payment_info?.extra?.LANGUAGE}${secret_code}LOGO=${payment_info?.extra?.LOGO}${secret_code}ORDERID=${order_id}${secret_code}OWNERADDRESS=${payment_info?.extra?.OWNERADDRESS}${secret_code}OWNERTOWN=${payment_info?.extra?.OWNERTOWN}${secret_code}OWNERZIP=${payment_info?.extra?.OWNERZIP}${secret_code}OWNERCTY=${payment_info?.extra?.OWNERCTY}${secret_code}PSPID=${payment_info?.extra?.PSPID}${secret_code}`;

    if(payment_info?.extra?.SUBSCRIPTION_ID){
        shasign_str +=`SUBSCRIPTION_ID=${payment_info?.extra?.SUBSCRIPTION_ID}${secret_code}SUB_AMOUNT=${payment_info?.extra?.SUB_AMOUNT}${secret_code}SUB_COM=${payment_info?.extra?.SUB_COM}${secret_code}SUB_COMMENT=${payment_info?.extra?.SUB_COMMENT}${secret_code}SUB_ENDDATE=${payment_info?.extra?.SUB_ENDDATE}${secret_code}SUB_ORDERID=${payment_info?.extra?.SUB_ORDERID}${secret_code}SUB_PERIOD_MOMENT=${payment_info?.extra?.SUB_PERIOD_MOMENT}${secret_code}SUB_PERIOD_NUMBER=${payment_info?.extra?.SUB_PERIOD_NUMBER}${secret_code}SUB_PERIOD_UNIT=${payment_info?.extra?.SUB_PERIOD_UNIT}${secret_code}SUB_STARTDATE=${payment_info?.extra?.SUB_STARTDATE}${secret_code}SUB_STATUS=${payment_info?.extra?.SUB_STATUS}${secret_code}`
    }

    var shasign_str2 = shasign_str

    // const hash = crypto.createHash('sha1').update(shasign_generate).digest('hex')
    console.log("shasign_generate", shasign_str2, sha512(shasign_str2))
    var shasign_generate = sha512(shasign_str)
    //var shasign_generate = ""

    return (
        <>
        <div className={`search-header property-header`}>
            <Layout seo_meta={seo_meta}>
            <div className="site-map payment_verify">
                <Container>
                    <Row>
                        <h1>Payment Verification</h1>

                        <Form action="https://payments.epdq.co.uk/ncol/prod/orderstandard.asp" method="post">
                        {/* <Form action="https://payments.epdq.co.uk/ncol/prod/querydirect.asp" method="post"> */}

                            {/* <input type="hidden" name="form_name" value={payment_info?.extra?.formname} />
                            <input type="hidden" name="form_type" value={payment_info?.extra?.form_type} />
                            <input type="hidden" name="form_label" value={payment_info?.extra?.form_label} />
                            <input type="hidden" name="to_email_id" value={payment_info?.extra?.to_email_id} />
                            <input type="hidden" name="admin_email" value={"Yes"} /> */}
                            {/* <input type="hidden" name="accept_rules" value={"on"} /> */}

                            {/* ============== Payment Fields ============== */}
                            <input type="hidden" name="PSPID" value={payment_info?.extra?.PSPID}/>
                            <input type="hidden" name="ORDERID" value={order_id} />
                            <input type="hidden" name="AMOUNT" value={amount} />
                            <input type="hidden" name="CURRENCY" value={payment_info?.extra?.CURRENCY} />
                            <input type="hidden" name="LANGUAGE" value={payment_info?.extra?.LANGUAGE} />
                            <input type="hidden" name="TITLE" value={payment_info?.extra?.TITLE} />

                            {/* <input type="hidden" name="MANDATEID" value={payment_info?.extra?.MANDATEID} />
                            <input type="hidden" name="SIGNDATE" value={payment_info?.extra?.SIGNDATE} />
                            <input type="hidden" name="SEQUENCETYPE" value={payment_info?.extra?.SEQUENCETYPE} /> */}

                            {/* <input type="hidden" name="BGCOLOR" value={payment_info?.extra?.BGCOLOR} />
                            <input type="hidden" name="TXTCOLOR" value={payment_info?.extra?.TXTCOLOR} />
                            <input type="hidden" name="TBLBGCOLOR" value={payment_info?.extra?.TBLBGCOLOR} />
                            <input type="hidden" name="TBLTXTCOLOR" value={payment_info?.extra?.TBLTXTCOLOR} /> 
                            <input type="hidden" name="FONTTYPE" value={payment_info?.extra?.FONTTYPE} />
                            <input type="hidden" name="TP" value={payment_info?.extra?.TP} />
                            */}

                            <input type="hidden" name="BUTTONBGCOLOR" value={payment_info?.extra?.BUTTONBGCOLOR} />
                            <input type="hidden" name="BUTTONTXTCOLOR" value={payment_info?.extra?.BUTTONTXTCOLOR} />
                            <input type="hidden" name="LOGO" value={payment_info?.extra?.LOGO} />
                            <input type="hidden" name="SHASIGN" value={shasign_generate} required="required" />

                            <input type="hidden" name="ACCEPTURL" value={payment_info?.extra?.ACCEPTURL} placeholder="ACCEPTURL" />
                            <input type="hidden" name="DECLINEURL" value={payment_info?.extra?.DECLINEURL} placeholder="declineurl" />
                            <input type="hidden" name="EXCEPTIONURL" value={payment_info?.extra?.EXCEPTIONURL} placeholder="exceptionurl" />
                            <input type="hidden" name="CANCELURL" value={payment_info?.extra?.CANCELURL} placeholder="cancelurl" />
                            <input type="hidden" name="HOMEURL" value={payment_info?.extra?.HOMEURL} placeholder="homeurl" />

                            {/* <input type="hidden" name="COM" value={payment_info?.extra?.COM} />
                            <input type="hidden" name="PM" value={payment_info?.extra?.PM} />
                            <input type="hidden" name="BRAND" value={payment_info?.extra?.BRAND} /> */}
                            {/* <input type="hidden" name="COMPLUS" value={payment_info?.extra?.COMPLUS} />
                            <input type="hidden" name="PARAMPLUS" value={payment_info?.extra?.PARAMPLUS} /> */}

                            <input type="hidden" name="CN" value={payment_info?.extra?.CN} />
                            <input type="hidden" name="EMAIL" value={payment_info?.extra?.EMAIL} />
                            <input type="hidden" name="OWNERZIP" value={payment_info?.extra?.OWNERZIP} />
                            <input type="hidden" name="OWNERADDRESS" value={payment_info?.extra?.OWNERADDRESS} />
                            {/* <input type="hidden" name="OWNERADDRESS2" value={payment_info?.extra?.OWNERADDRESS2} /> */}
                            <input type="hidden" name="OWNERTOWN" value={payment_info?.extra?.OWNERTOWN} />
                            <input type="hidden" name="OWNERCTY" value="UK" />

                            {/* ============ Subscription fields =========== */}
                            {payment_info?.extra?.SUBSCRIPTION_ID ? <>
                                <input type="hidden" name="SUBSCRIPTION_ID" value={payment_info?.extra?.SUBSCRIPTION_ID} required="required" />
                                <input type="hidden" name="SUB_AMOUNT" value={payment_info?.extra?.SUB_AMOUNT} required="required" />
                                <input type="hidden" name="SUB_COM" value={payment_info?.extra?.SUB_COM} required="required" />
                                {/* <input type="hidden" name="SUB_ORDERID" value={payment_info?.extra?.SUB_ORDERID} required="required" /> */}
                                <input type="hidden" name="SUB_ORDERID" value={order_id} required="required" />
                                <input type="hidden" name="SUB_PERIOD_UNIT" value={payment_info?.extra?.SUB_PERIOD_UNIT} required="required" />
                                <input type="hidden" name="SUB_PERIOD_NUMBER" value={payment_info?.extra?.SUB_PERIOD_NUMBER} required="required" />
                                <input type="hidden" name="SUB_PERIOD_MOMENT" value={payment_info?.extra?.SUB_PERIOD_MOMENT} required="required" />
                                <input type="hidden" name="SUB_STARTDATE" value={payment_info?.extra?.SUB_STARTDATE} required="required" />
                                <input type="hidden" name="SUB_ENDDATE" value={payment_info?.extra?.SUB_ENDDATE} required="required" />
                                <input type="hidden" name="SUB_STATUS" value={payment_info?.extra?.SUB_STATUS} required="required" />                            
                                <input type="hidden" name="SUB_COMMENT" value={payment_info?.extra?.SUB_COMMENT} required="required" />
                            </> : ''
                            }
                            
                            {/* ============== Payment Fields ============== */}

                            {/* <Table striped bordered responsive width={"100%"} className="payment_info" cellPadding={20}>
                                <tbody>
                                    <tr>
                                        <td className="label">Type</td>
                                        <td className="label">{payment_info?.extra?.persontype}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Department</td>
                                        <td className="label">{payment_info?.extra?.department}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Name</td>
                                        <td className="label">{payment_info?.extra?.salutation} {payment_info?.extra?.first_name} {payment_info?.extra?.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Email</td>
                                        <td className="label">{payment_info?.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Property Number</td>
                                        <td className="label">{payment_info?.extra?.property_name_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Address 1</td>
                                        <td className="label">{payment_info?.extra?.property_address_line_1}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Address 2</td>
                                        <td className="label">{payment_info?.extra?.property_address_line_2}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Town/City</td>
                                        <td className="label">{payment_info?.extra?.property_town_city}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Postcode</td>
                                        <td className="label">{payment_info?.extra?.property_postcode}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Name</td>
                                        <td className="label">{payment_info?.extra?.billing_salutation} {payment_info?.extra?.billing_first_name} {payment_info?.extra?.billing_last_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Property Number</td>
                                        <td className="label">{payment_info?.extra?.billing_property_name_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Address 1</td>
                                        <td className="label">{payment_info?.extra?.billing_property_address_line_1}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Address 2</td>
                                        <td className="label">{payment_info?.extra?.billing_property_address_line_2}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Town/City</td>
                                        <td className="label">{payment_info?.extra?.billing_property_town_city}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Billing Postcode</td>
                                        <td className="label">{payment_info?.extra?.billing_property_postcode}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Amount</td>
                                        <td className="label">{process.env.GATSBY_CURRENCY}{payment_info?.extra?.amount_full}.{payment_info?.extra?.amount_pence}</td>
                                    </tr>
                                    {payment_info?.extra?.SUB_COMMENT &&
                                    <tr>
                                        <td className="label">Reason</td>
                                        <td className="label">{payment_info?.extra?.SUB_COMMENT}</td>
                                    </tr>
                                    }

                                    {payment_info?.extra?.comments &&
                                    <tr>
                                        <td className="label">Details</td>
                                        <td className="label">{payment_info?.extra?.comments}</td>
                                    </tr>
                                    }
                                </tbody>
                            </Table> */}
                            
                            {payment_info &&
                                <PaymentTable payment_info={payment_info} />
                            }

                            <div className="btn_wrapper">
                                <a className="btn btn-outline" onClick={goBack}>Go Back</a>

                                <input className="btn btn-primary" type={"submit"} value={"Proceed to Secure Payment"}/>
                            </div>

                        </Form>

                    </Row>
                </Container>
                </div>
            </Layout>
        </div>
        </>
    )
}

export default PaymentVerify